import { ISearchOverlay } from 'components/HeaderNavigation/components/MobileNavigation';
import RootLayoutClient from 'components/PageLevelComponents/ClientLayout';
import ClientProductsSubcategorySlugPage, {
  MinifiedCategory,
} from 'components/PageLevelComponents/ClientProductsSubcategorySlugPage';

import type { ISocialMediaComponent } from 'components/SocialMedia/types';
import { LOCALE_DEFAULT } from 'config/config';
import { ProductsSubcategoryPageController } from 'server/controller/page/products/subcategoryPageController';
import { ProductSubcategoryPage } from 'server/repository/hygraph/generated/graphqlTypes';
import { ProductsSubcategoryPageResource } from 'server/resource/page/productsSubcategoryPageController';

const RootPage = (props: ProductsSubcategoryPageResource) => {
  const {
    metaData,
    searchItemLabels,
    recipeCopy,
    breadcrumbsCMSData,
    footerNavData,
    footerImage,
    usercentricsIds,
    locale,
    searchOverlayData,
    countrySelectorData,
    newsletterData,
    contactUsUrl,
    difficultyCopy,
    getHeaderNav,
    getMobileNavData,
    popularSearchData,
    cardCarouselCMSData,
    recallData,
    subCategoryProducts,
    subcategory,
    followUsData,
    globalLabels,
    isSubSubCategory,
    category,
    productsCopy,
    pathTranslations,
  } = props;

  return (
    <RootLayoutClient
      globalMetadata={metaData}
      getHeaderNav={getHeaderNav}
      getMobileNavData={getMobileNavData}
      popularSearchData={popularSearchData}
      footerNavData={footerNavData}
      footerImage={footerImage}
      usercentricsIds={usercentricsIds}
      locale={locale}
      searchOverlayData={searchOverlayData}
      countrySelectorData={countrySelectorData}
      newsletterData={newsletterData}
      contactUsUrl={contactUsUrl}
      difficultyCopy={difficultyCopy as Record<string, string>}
      cardCarouselCMSData={cardCarouselCMSData}
      recallData={recallData}
      pathTranslations={pathTranslations}
    >
      <ClientProductsSubcategorySlugPage
        subCategoryProducts={subCategoryProducts}
        subcategory={subcategory as ProductSubcategoryPage}
        followUsData={followUsData as unknown as ISocialMediaComponent}
        globalLabels={globalLabels}
        breadcrumbsCMSData={breadcrumbsCMSData}
        category={category as MinifiedCategory}
        isSubSubCategory={isSubSubCategory}
        productsCopy={
          productsCopy as {
            value: string;
          }
        }
        difficultyCopy={difficultyCopy as Record<string, string>}
        searchItemLabels={searchItemLabels as Record<string, string>}
        searchOverlayData={searchOverlayData as ISearchOverlay}
        recipeCopy={recipeCopy}
      />
    </RootLayoutClient>
  );
};

type GetServerSidePropsParams = {
  locale: string;
  params: {
    slug: string[];
  };
  defaultLocale: string;
  resolvedUrl: string;
};

export const getServerSideProps = async ({
  locale,
  params,
  defaultLocale,
  resolvedUrl,
}: GetServerSidePropsParams) => {
  return ProductsSubcategoryPageController.getCachedSubcategoryPageData({
    resolvedUrl,
    locale: locale || defaultLocale || LOCALE_DEFAULT,
    slug: params.slug,
  });
};

export default RootPage;
